@media (max-width: 1024px) {
    .contactFieldsMainDiv{
     zoom: 80% !important;
    }

    .contact-detailsContainer {
        zoom: 80% !important;
    }
 }


@media (max-width: 768px) {
    .contactFieldsMainDiv{
        zoom: 60% !important;
       }

       .contact-detailsContainer {
        zoom: 60% !important;
    }
 }
 
 @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
    .contactUsH{
        font-size: 22px !important;
    }
    

    .contactFieldsMainDiv{
        display: flex !important;
        flex-direction: column !important;
        margin-left: 30px !important;
        margin-right: 30px !important;
    }
}