
.footer {
  background:  #141212;
  color: #fff;
  display: flex;
  justify-content: space-around;
  padding: 15px;
  flex-wrap: wrap; 
  justify-content: space-between;
}

.footer-text {
  font-size: 13px;
}

.footer-logo {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
  order: 1;
  flex-basis: 100%; 
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin-left: 15%;
  margin-right: 15%;
  flex-basis: 100%; /* Take full width of the container */
  margin-top: 12vh;
}

.footer-column {
  flex: 1;
  order: 2; /* Place the columns second in the order */
  flex-basis: 25%; 
  
}

.footer-column h4 {
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 5px;
}

.social-media-logos {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.social-media-logos svg {
  margin-right: 10px;
}

/* Add these styles for the links */
ul li a {
  color: yellow; 
  text-decoration: none; 
}

ul li a:hover {
  text-decoration: underline; /* Add underline when hovering over the links */
}

.scan-to-download {
  text-align: center;
}

.qr-code-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 1px;
}

.appstore-icon,
.playstore-icon {
  border-radius: 5px;
  padding: 10px;
  display: flex;
  width: 150px;
  height: 200px;
  margin-top: 10%;
}

.appstore-icon {
  margin-right: 10px;
  background-image: url('../Images/asqr.png');
  background-size: contain;
}

.playstore-icon {
  margin-right: 10px;
  background-image: url('../Images/gpqr.png');
  background-size: contain;
}


.footer-links a {
  color: #fff;
  text-decoration: none;

}

@media (max-width: 1024px) {
  .ullinks {
    margin-right: 30px !important;
  }
}




/* Responsive styles */



@media (max-width: 375px) {

  .ullinks {
    margin-right: 0px !important;
  }

  .footer {
    flex-direction: column;
    align-items: center;  
    display: flex;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center !important;
    margin: 0 !important;
  }

  .footer-logo {
    max-width: 200px; /* Limit the maximum width of the logo */
    justify-content: center;
    align-items: center;
    margin-right: 25px !important;
    margin-left: 80px !important;
  }

  .footer-column {
    order: 2;
    flex-basis: 100%;
  }
}





@media (max-width: 768px) {

.footer {
    flex-direction: column !important;
    align-items: center !important;   
    display: flex !important;
    width: 100%;

     }

     .footer-logo {
      max-width: 500px; /* Limit the maximum width of the logo */
      justify-content: center;
      align-items: center;
      margin-left: 50px !important;
      margin-right: 50px;

    }

  .ullinks {
    margin-right: 0px !important;
  }


  /* .footer {
    flex-direction: column;
    align-items: center; 

  } */

  .footer-content {
    flex-direction: column;
    align-items: center;
  }



  .footer-column {
    order: 2;
    flex-basis: 100%;
  }

  .footerlink{
    margin-left: 20px;
    margin-right: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .footer-text{
    margin-left: 20px !important;
    margin-right: 20px !important;
    text-align: justify;
  }
}




@media (max-width: 767px) {

  .ullinks {
    margin-right: 0px !important;
  }


  .footer {
    flex-direction: column;
    align-items: center;  
    display: flex;
    zoom: 80%;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center !important;
    margin: 0 !important;
  }

  .footer-logo {
    max-width: 200px; /* Limit the maximum width of the logo */
    justify-content: center;
    align-items: center;
    margin-left: 150px;
    margin-right: 50px;
  }

  .footer-column {
    order: 2;
    flex-basis: 100%;
  }
}


@media (max-width: 320px) {
  .footer {
 zoom: 80%;
 flex-direction: column;
 align-items: center;  
 display: flex;
  }


  .footer-content {
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
    justify-content: center !important;
    margin: 0 !important;
  }

  .footer-logo {
    max-width: 200px; /* Limit the maximum width of the logo */
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    margin-right: 50px;
  }

  .footer-column {
    order: 2;
    flex-basis: 100%;
  }
   
}

@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .footer {
    flex-direction: column !important;
    align-items: center !important;   
    display: flex !important;
    width: 100%;

     }

     .footer-logo {
      max-width: 500px; /* Limit the maximum width of the logo */
      justify-content: center;
      align-items: center;
      margin-left: 50px !important;
      margin-right: 50px;

    }

    .footer-text{
      margin-left: 20px !important;
      margin-right: 20px !important;
      text-align: justify;
      font-size: 17px;
    }

    .social-media-logos{
      margin-left: 20px;
      margin-right: 20px;
    }
    
    .footerlink{
      margin-left: 20px;
      margin-right: 20px;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .ullinks{
      font-size: 15px;
      display: flex;
      flex-direction: column;
      padding: 0px !important;
      margin-right: 0px !important;
    }
    

}



@media only screen and (max-width: 767px) {
  .footer {
    flex-direction: column !important;
    align-items: center !important;   
    display: flex !important;
    width: 100%;

     }

     .footer-logo {
      max-width: 500px; /* Limit the maximum width of the logo */
      justify-content: center;
      align-items: center;
      margin-left: 110px;
      margin-right: 50px;

    }

    .footer-text{
      margin-left: 20px !important;
      margin-right: 20px !important;
      text-align: justify;
      font-size: 17px;
    }

    .social-media-logos{
      margin-left: 20px;
      margin-right: 20px;
    }
    
    .footerlink{
      margin-left: 20px;
      margin-right: 20px;
      justify-content: center;
      display: flex;
      align-items: center;
    }

    .ullinks{
      font-size: 15px;
      display: flex;
      flex-direction: column;
      padding: 0px !important;
      margin-right: 0px !important;
    }
    

}