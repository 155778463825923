.noteContainer {
    width: 100%;
    margin-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
}

.noteHeader {
    display: flex;
}

.noteTitle {
    font-weight: bold;
    font-family: futuraheavy;
    font-size: 17px;
}

.noteSub {
    font-size: 14px;
    font-family: futurabook;
}

.noteText {
    font-size: 17px;
    font-family: futurabook;
}


.load-more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  
}

.loadmoreButton{
    border: none;
    color: #141212;
    background-color: transparent;
}
