/* Custom CSS for steps container box */
.stepsContainer1
{
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 1.jpg');
  background-size: cover;
  background-position: center;
  height: 30vh;
  width: 28rem;
  background-repeat: no-repeat;
}

.stepsContainer2 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 2.jpg');
  background-size: cover;
  background-position: center;
  height: 30vh;
  width: 28rem;
  background-repeat: no-repeat;
}

.stepsContainer3 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 3.jpg');
  background-size: cover;
  background-position: center;
  height: 30vh;
  width: 28rem;
  background-repeat: no-repeat;
}

.stepsContainer4 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 4.jpg');
  background-size: cover;
  background-position: center;
  height: 30vh;
  width: 28rem;
  background-repeat: no-repeat;
}



/* Custom CSS for the grid layout */
.stepsGrid {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  display: grid;
  flex-direction: column;
  gap: 25px;
    max-width: 80%;
  margin-left: 12%;
}



/* Responsive Styles */



@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .stepsContainer1
  {
    border: 1px solid #4f4f4f;
    border-radius: 20px;
    padding: 16px;
    margin-bottom: 20px;
    color: #eeeeee;
    background-image: url('../../Steps/Step\ 1.jpg');
    background-size: cover;
    background-position: center;
    height: 450px;
    width: 650px !important;
    background-repeat: no-repeat;
  }

  
.stepsContainer2 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 2.jpg');
  background-size: cover;
  background-position: center;
  height: 450px;
  width: 650px !important;
  background-repeat: no-repeat;
}

.stepsContainer3 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 3.jpg');
  background-size: cover;
  background-position: center;
  height: 450px;   
  width: 650px !important;
  background-repeat: no-repeat;
}

.stepsContainer4 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 4.jpg');
  background-size: cover;
  background-position: center;
  height: 450px;
  width: 650px !important;
  background-repeat: no-repeat;
}

.howitworksmaindiv{
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-top: 40px !important;
  padding-bottom: 40px !important; 
}

.howitworkstitle{
  font-size: 20px !important;
}

.howitworksdiv{
  margin: 10px !important;
}

.howitworkstitle2{
  font-size: 20px !important;
}

.stepstitle{
  font-size: 20px !important;
  text-align: center;
}

.stepssubtitlte {
  font-size: 15px !important;
  text-align: center !important;
}

.stepsText{
  margin-bottom: 0px !important;
}

}






@media screen and (max-width: 902px) {
  .stepsGrid {
    grid-template-columns: repeat(2, 1fr); /* Change to 2 columns on smaller screens */

  }
}

@media screen and (max-width: 620px) {
  .stepsGrid {
    grid-template-columns: 1fr; /* Change to 1 column on even smaller screens */
    zoom: 70%;
  }
}

@media screen and (max-width: 820px) {
  .stepsGrid {
    grid-template-columns: repeat(1, 1fr); /* Change to 2 columns on smaller screens */
  }
}

@media (max-width: 1024px) {
  /* .headline {
    padding: 40px 20px; 
    
  }

  .headline h2 {
    margin-left: 15%;
  }

  .headline p {
    font-size: 70%;
    margin-left: 15%;
  } */

  .howitworksmaindiv {
    zoom: 80% !important;
  }
}

@media screen and (max-width: 425px) {
  .stepsContainer1
  {
    border: 1px solid #4f4f4f;
    border-radius: 20px;
    padding: 16px;
    margin-bottom: 20px;
    color: #eeeeee;
    background-image: url('../../Steps/Step\ 1.jpg');
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100% !important;
    background-repeat: no-repeat;
  }

  
.stepsContainer2 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 2.jpg');
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100% !important;
  background-repeat: no-repeat;
}

.stepsContainer3 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 3.jpg');
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100% !important;
  background-repeat: no-repeat;
}

.stepsContainer4 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 4.jpg');
  background-size: cover;
  background-position: center;
  height: 300px;
  width: 100% !important;
  background-repeat: no-repeat;
}
}





@media only screen and (max-width: 767px) {
  .stepsContainer1
  {
    border: 1px solid #4f4f4f;
    border-radius: 20px;
    padding: 16px;
    margin-bottom: 20px;
    color: #eeeeee;
    background-image: url('../../Steps/Step\ 1.jpg');
    background-size: cover;
    background-position: center;
    height: 350px;
    width: 100% ;
    background-repeat: no-repeat;
  }

  
.stepsContainer2 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 2.jpg');
  background-size: cover;
  background-position: center;
  height: 350px;
  width: 100% ;
  background-repeat: no-repeat;
}

.stepsContainer3 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 3.jpg');
  background-size: cover;
  background-position: center;
  height: 350px;
  width: 100% ;
  background-repeat: no-repeat;
}

.stepsContainer4 {
  border: 1px solid #4f4f4f;
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 20px;
  color: #eeeeee;
  background-image: url('../../Steps/Step\ 4.jpg');
  background-size: cover;
  background-position: center;
  height: 350px;
  width: 100% ;
  background-repeat: no-repeat;
}

.howitworksmaindiv{
  padding-right: 10px ;
  padding-left: 10px ;
  padding-top: 40px ;
  padding-bottom: 40px ; 
}

.howitworkstitle{
  font-size: 20px ;
}

.howitworksdiv{
  margin: 10px ;
}

.howitworkstitle2{
  font-size: 20px ;
}

.stepstitle{
  font-size: 30px ;
  text-align: center;
}

.stepssubtitlte {
  font-size: 20px ;
  text-align: center ;
}

.stepsText{
  margin-bottom: 0px ;
}

}



