/* UserInstructions.css */
.centerContainer {
    background: rgb(30, 28, 28);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 15vh); /* Adjust height to prevent excessive movement */
  }
  
  .howItWorks {
    text-align: center;
    margin-bottom: 20px; /* Add margin to the bottom of the element */
  }
  
  .howItWorks h3 {
    font-size: 100%;
    color: #f6c90e;
  }
  
  .howItWorks h2 {
    font-size: 24px;
  }
  
  .carousel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 50px;
    justify-content: center; /* Add justify-content to center the carousel on smaller screens */
  }
  
  .carousel button {
    font-size: 100px;
    background: none;
    border: none;
    color: #8b8a8b;
    cursor: pointer;
  }
  
  .carousel img {
    height: 400px;
    width: 40rem;
    max-width: 60%; /* Adjust the max-width to 60% on smaller screens */
    margin: 0 20px;
  }
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .howItWorks h2 {
      font-size: 18px;
    }
  
    .carousel img {
      max-height: 300px;
      margin: 0 10px;
      width: 80%; /* Adjust the width of the image on smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .howItWorks h2 {
      font-size: 16px;
    }
  
    .carousel {
      flex-direction: column;
      max-width: 60%; 
      margin: 0 auto; 
      align-items: center;
    }
  
    .carousel button {
      font-size: 60px;
    }
  
    .carousel img {
      max-height: 200px;
      margin: 10px 0;
      width: 100%;
    }
  
    .howItWorks {
      padding: 0 20px; /* Add padding to prevent title overlap */
    }
  }
  