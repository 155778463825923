.toggle {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px;
  }
  
  .toggle input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
/* Styles for the slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E9B6BB; /* Default background color */
  transition: 0.4s;
  border-radius: 34px;
}

/* Styles for the circle indicator */
.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  bottom: 4px;
  background-color: white; /* Default circle color */
  transition: 0.4s;
  border-radius: 50%;
}

/* Change background color and circle color when slider is checked */
input:checked + .slider {
  background-color: #B3D8BB; /* Checked background color */
}

input:checked + .slider:before {
  background-color: #28A745; /* Checked circle color */
  transform: translateX(26px);
  left: 18px;
}

/* Change circle color when slider is not checked */
input:not(:checked) + .slider:before {
  background-color: #DC3545; /* Unchecked circle color */
  left: 3px;
}

/* Example styles for text colors */
.yes-text {
  color: #28A745;
}

.no-text {
  color: #DC3545;
}


  

  /* Existing styles... */

.iconBoxes {
    display: flex;
    align-items: center;
    margin-left: 25px;
    margin-top: 0%;
  }
  
  .iconBox {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ccc;
    border-radius: 5px;
    margin-right: 6px;
  }
  