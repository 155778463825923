@media (max-width: 1024px) {
    .additionalFeatures{
     zoom: 80% !important;
    }

    .mainBodyDownloadAdditionalFeatures{
        zoom: 60%;
     }
 }


@media (max-width: 768px) {
    .additionalFeatures{
     zoom: 60% !important;
    }

    .mainBodyDownloadAdditionalFeatures{
       zoom: 60%;
    }
 }
 

 @media (max-width: 767px) {
  /* .downloadphone3{
    background-size: contain;
    width: 180px;
    margin-top: 350px;
  } */

  .mainBodyDownloadAdditionalFeatures{
    display: flex !important; 
    flex-direction: column !important;

  }

  .download-grid{
    display: flex !important;
    flex-direction: column !important;
    
  }

  .feature-title{
    font-size: 22px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  
  .box3{
    margin-top: 30px;
  }

  .app-link-container{
    width: 250px !important;
    height: 150px !important;
  }

  .googlePlay-link-container{
    width: 250px !important;
    height: 150px !important;
  }

  .downloadbuttonss{
    margin-left: 0px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .additionalFeaturesDiv{
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 50px;
  }

 }


 @media only screen and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .downloadphone3{
    background-size: contain;
   background-position-x: 100%;
   background-position-y: 50%;
  }
 }

 @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .additionalFeaturesDiv{
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 50px;
  }

  .feature-title{
    font-size: 22px;
    text-align: center;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .downloadtheappP{
    font-size: 20px;
    text-align: justify;
    width: 100% !important;
    margin-left: 20px;
    margin-right: 20px;
  }

  .downloadTheAdditionalFeatures{
    font-size: 22px !important;
  }

  .additionalFeaturesPp {
    font-size: 20px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    width: 100% !important;
  }

  .download-title{
    margin-left: 30px;
    margin-right: 30px;
  }

  .downloadnowtitle{
    font-size: 20px !important;
  }

  .downloadnowdescription{
    font-size: 18px !important;
  }

  .downloadbuttonss{
    margin-left: 0px !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .app-link-container{
    width: 300px !important;
    height: 200px !important;
  }

  .googlePlay-link-container{
    width: 300px !important;
    height: 200px !important;
  }
  
 }
 