/* AdditionalFeatures.css */
.additionalFeatures {
  background: #141212;
  color: #fff;
  padding: 20px;
  min-height: 70vh;
}

.feature-header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.feature-content {
  width: 65%;
}

.feature-title {
  margin-top: 10vh;
  margin-bottom: 5vh;
  font-size: 50px;
  font-family: futura;
  margin-right: 10%;
}

.mock-image-container {
  width: 35%;
  height: 60vh;
  background-image: url('../../Images/Homepage-Phone.png'); 
  background-size:contain; 
  background-repeat: no-repeat;
  position: relative;
}

.mock-image-download {
  width: 100%;
  height: 110%;
  background-image: url('../../Images/Download\ the\ App-Phone1.png');
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  
}



.mock-image {
  max-width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
  margin-top: 10%;
}

.additionalFeaturesContent {
  max-width: 75%;
  margin: 0 auto; /* Center the contents */
}

p {
  font-size: 18px;
}

.app-links {
  display: flex;
  justify-content: flex-start;
  gap: 11px;
  max-width: 100%;
  margin-top: 2vh;
  font-family: futura;
}

.app-link-container {
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  background-image: url('../../Images/AppStoreButton.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
}

.googlePlay-link-container{
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  background-image: url('../../Images/GooglePlayButton.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
}

.app-link-button {
  background-color: transparent; /* Change the background to transparent */
  color: #f6c90e; /* Change the font color to #F6C90E */
  border: none;
  cursor: pointer;
  
}

.app-icon-and-button-container {
  display: flex;
  align-items: center;
}

.app-link-icon {
  font-size: 40px;
  margin-right: 8px;
}


.downloadnowtitle {
  font-family: futura;
  margin-top: 10px;
  font-size: 17px;
}

.downloadnowdescription{
  font-family: futurabook;
  font-size: 15px;
}

.downloadphone3{
  background-image: url('../../Images/Download\ the\ App-Phone2.png');
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 395px;
}



/* Responsive Styles */
/* @media screen and (max-width: 1025px) {
  .feature-title {
    font-size: 20px;
    width: 100%;
  }

  .feature-content {
    width: 100%;
  }

  .mock-image-container {
    width: 100%;
    margin: 20px 0;
    
  }

  .mock-image-download {
    width: 100%;
    margin: 20px 0;
  }

  .app-links {
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .app-link-container
  {
    margin: 10px 0;

  }

  .googlePlay-link-container {
    margin: 10px 0;
  }

  .app-icon-and-button-container {
    margin: 5px 0;
  }

  .downloadphone3 {
    background-size: contain;
  }
} */


@media screen and (max-width: 1024px) {
  .additionalFeatures {
   zoom: 80% !important;

  }

}


@media screen and (max-width: 768px) {
  .additionalFeatures {
   zoom: 60% !important;
  }

}

@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .feature-title{
    font-size: 20px !important;
    width: 100%;
    text-align: left;
    margin-top: 0px !important;
  }

  .feature-header{
    margin-left: 0px !important;
    margin-right: 0px !important;



  }

  .feature-content{
    display: flex;
    flex-direction: column;
    width: 100% !important;
  }

  .featuretext{
    font-size: 18px;
  }

  .app-link-container {
  
   
    height: 200px !important;
    width: 500px !important;
  }
  
  .googlePlay-link-container{
   
    height: 200px !important;
    width: 500px !important;
  }

  .feature-header{
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: space-around !important;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .mock-image-container {
    height: 500px;
    background-size: contain;
 

  }

  .featuretext{

    text-align: center;

   
  }
  
}



@media only screen and (max-width: 767px) {
  .feature-title{
    font-size: 30px ;
    width: 100%;
    text-align: center;
    margin-top: 0px ;
  }

  .feature-header{
    margin-left: 0px ;
    margin-right: 0px ;

  }

  .feature-content{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .featuretext{
    font-size: 18px;
  }

  .app-link-container {
   
   
    height: 200px ;
    width: 500px ;
  }
  
  .googlePlay-link-container{
   
    height: 200px ;
    width: 500px ;
  }

  .feature-header{
    display: flex;
    flex-direction: column-reverse ;
    justify-content: space-around ;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .mock-image-container {
    height: 400px;
    background-size: contain;
 

  }

  .featuretext{
   
    text-align: center;

   
  }

  .additionalFeatures{
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100%;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;

  }
  
}




