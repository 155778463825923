.changePasswordContainer{
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFFFFF;
    border-radius: 20px;
}


.password-input-container {
    display: flex;
    align-items: center;
    border: 1px solid #121212;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 12px;
    width: 25rem;
    height: 16%;
    margin: 10px;
    width: 100%;
    padding: 20px;
    font-family: "futurabook";
    font-weight: bold;

  }