
.finalDetails-container {
    display: flex;
    flex-direction: column;
    justify-content:center;
    height: 100%;
    background-color: #ffffff;
    border-radius: 60px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-top: 2%;
    width: 60%;
    margin-bottom: 5%;
    padding: 20px;
    font-family: 'futurabook', sans-serif;
    padding:40px;
}
  
  .finalTitleDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

  }


  .finish-row {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Each field takes one column by default */
    align-items: center;
    gap:20px;
  }
  
  .finish-column {
    display:block;
    align-items: center;
  }
  
  .detailsTitle {
    margin-right: 8px;
    font-size: 16px;
    color: #7e7e7e;
  }
  
  .finalDetails {
    border-bottom: 2px solid #7e7e7e; /* Add a line below finalDetails */
    padding-bottom: 4px; /* Gap from the finalDetails text */
    font-size: 22px;
    
  }


  
  /* Apply the 2-column layout for "Master / Electricians License Number" */
  .finish-row.masterNumber {
    grid-template-columns: 1fr 1fr;
  }
  
  /* Apply the 1-column layout for "Company Details" */
  .companyDetails {
    grid-template-columns: 1fr;
  }

  /* Apply the 2-column layout for "Email" field */
.wholeRow {
    grid-column: 1 / -1; /* Span across all columns */
  }

  
