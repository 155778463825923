

/* remove margin here soon */
.policy-container{
    width: 860px;
    background-color: #ffff ;
    border-radius: 35px;
    padding: 50px;
    margin-top: 50px;
    margin-right: 80px;
}

.disclaimer-container{
    width: 860px;
    background-color: #ffff ;
    border-radius: 35px;
    padding: 50px;
    margin-top: 50px;
    margin-right: 80px;
}

.privacypolicytitle{
    font-size: 45px;
}

.privacy-subtitle {
    font-family: futurabook;
    font-size: 30px;
}

.privacyText {
    font-size: 18px;
    font-family: futurabook;
}

.contact-icon {
    display: inline-block;
    border: 1px solid black; /* Adjust the border color and thickness */
    border-radius: 10px;
    padding: 8px;
    width: 50px;
    margin-right: 10px; /* Adjust spacing between icon and text */
    display: flex;
    justify-content: center;
    height: 50px;
    font-size: 30px;
    margin-top: 2px;
    background-color: #ffffff;
  }

.infoTitle{
    display: flex;
    flex-direction: row;

}

.infoName {
    font-family: futuraheavy;
    font-size: 20px;
    margin-bottom: -1px;
}

.infoSubtitle{
    font-family: futurabook;
    font-size: 20px;
    margin-bottom: 35px;
}




@media (max-width: 1900px) {

.privacypolicytitle{
    font-size: 35px;

}

}





@media (max-width: 768px) {

    .policy-container{
      zoom: 75%;
    
    }
    .disclaimer-container{
        zoom: 60%;
    }
    

    .privacypolicyfooter{
        zoom: 60%;
    }
    }

    @media (max-width: 767px) {

        .policy-container{
          zoom: 60%;
        
        }
        .disclaimer-container{
            zoom: 60%;
        }
        
    
        .privacypolicyfooter{
            zoom: 60%;
        }
        }