.safeTtagBenefits {
  background: #303841;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-wrap: wrap; /* Allow flex items to wrap to the next row on smaller screens */

}


.title {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;

  
}

.columnsContainer {
  display: flex;
  gap: 20px;
  flex: 1;
  margin-bottom: 70px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.row {
  display: flex;
  gap: 20px;
}

.HeadText {
  font-family: futura;
}

/* Apply the font size directly to the .HeadText class */
.HeadText {
  font-size: 36px;
}

.subText {
  flex: 1;
  font-size: 15px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
}

.benefitItem {
  display: flex;
  gap: 10px;
  align-items: center;
  
}

.benefitContent {
  flex: 1;
}

.rightColumn h4 {
  font-size: 18px;
  margin: 0;
  color: #eee;
}

.rightColumn p {
  color: #fff;
}

/* Styles for the React icons */
.icon1,
.icon2,
.icon3 {
  font-size: 25px;
  color: #eeee;
}

.icon2 {
  animation: rotating 2s linear infinite;
}

/* Media Queries for Responsive Layout */
@media screen and (max-width: 1024px) {
  .safeTtagBenefits {
    justify-content: center; /* Center the entire component on smaller screens */
    zoom: 80% !important;
  }

}


@media screen and (max-width: 768px) {
  .safeTtagBenefits {
    justify-content: center; /* Center the entire component on smaller screens */
    zoom: 60% !important;
  }

}

@media screen and (max-width: 480px) {
  .HeadText {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .headtextbenefits{
    font-size: 22px !important;
  } 

  .headtextbenefits{
    max-width: none !important;
    min-width: 100% !important;
    text-align: center;
    margin-left: 0px !important;
  }

  .safettagbenefitstitle{
    text-align: center !important;
  }


.columnsContainer{
  display: flex;
  flex-direction: column;
  margin-left: 20px !important;
  margin-right: 20px !important;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.leftColumn{
  margin-right: 0px !important;
}

  
}



/* @media screen and (max-width: 767px)  {
  .headtextbenefits{
    font-size: 25px ;
  } 

  .headtextbenefits{
    max-width: none ;
    min-width: 100% ;
    text-align: center;
    margin-left: 0px ;
  }

  .safettagbenefitstitle{
    text-align: center ;
  }
  
} */


