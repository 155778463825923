/* Header.css */



.header {
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    /* padding */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: auto;
    min-height: 10vh;
  }
  
  .logo img {
    height: 60px;
  }
  
  .contact-details {
    margin-left: auto;
    display: flex;
  }
  
  .address,
  .email {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .address p,
  .email p {
    margin: 0;
    margin-left: 5px;
  }
  
  /* Styles for the Fi icons */
  .address svg,
  .email svg {
    font-size: 24px;
    margin-right: 5px;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  
    .contact-details {
      margin-left: 0;
      margin-top: 10px;
    }
  
    .address,
    .email {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  