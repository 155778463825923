




  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px !important;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2.5%;
    margin-left: 150px;
    margin-right: 150px;
    z-index: 1;
    margin-top: 2%;
    width: 75%;

  }
  
  .input-container {
    display: flex;
    align-items: center;
    border: 1px solid #121212;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 12px;
    width: 25rem;
    height: 16%;
    margin: 10px;
    width: 100%;
    padding: 20px;
    font-family: "futurabook";
    font-weight: bold;

  }
  
  .loginTitle{
    font-size: 40px;
  }

  
  .input-icon {
    font-size: 20px;
    color: #303841; /* Default color */
    margin-left: 8px;
  }
  
  .password-icon {
    font-size: 20px;
    color: #303841; /* Default color */
    cursor: pointer;
  }
  
  input {
    border: none;
    outline: none;
    flex: 1;
    margin-right: 8px;
    background-color: transparent;
    font-size: 16px;
    color: #000000;
    font-family: "futurabook";
    font-weight: bold;
  }
  
  input::placeholder {
    color: #767575;
  }
  
  input:focus {
    color: #020202;
  }
  
  .input-container:focus-within .input-icon {
    color: #f6c90e; /* Icon color on focus */
  }
  
  .input-container:focus-within .password-icon {
    color: #f6c90e; /* Icon color on focus */
  }
  
  .checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    gap: 50px;
  }
  
  label {
    margin-left: 8px;
    color: #030303;
    font-family: "futurabook";
    font-weight: bold;
  }
  
  .login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6c90e;
    color: #141212;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 12px;
    width: 100%;
    height: 6.5vh;
  }
  
  .login-icon {
    font-size: 20px;
    margin-left: 8px;
  }
  
  .forgot-password a {
    color: #333333;
    text-decoration: none;
  }
  
  .register-link {
    margin-bottom: 25px;
    margin-top: 5px;
  }
  
  .register-link a {
    color: #333333;
    text-decoration: none;
  }
  
  .register-link strong {
    font-weight: bold;
    font-family: "futurabook";
  }
  

  /* Styles for the custom checkbox */
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 14px;
  height: 14px;
  border: 1px solid #F6C90E ;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  position: relative;
}

.custom-checkbox:checked::before {
  content: '✓'; /* Checkmark symbol */
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #F6C90E ; /* Color for the checkmark */
}

.custom-checkbox:checked {
  border: 1px solid #F6C90E ;
  /* Background color when checked */
}

.copyright {
  margin-bottom: 3px;
}



@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .loginTitle{
    font-size: 25px !important;
  }

  .login-container{
    padding: 50px !important;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    border: 1px solid #121212;
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 12px;
    width: 25rem;
    height: 100px !important;
    margin: 10px;
    width: 100%;
    padding: 25px;
    font-family: "futurabook";
    font-weight: bold;

  }

  .login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6c90e;
    color: #141212;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 15px;
    cursor: pointer;
    margin-bottom: 12px;
    width: 100%;
    height: 75px;
  }
  

  .register-link{
    font-size: 10px;
  }


  .input-container{
    font-size: 12px;
  }
  


}





@media (max-width: 1900px) {
  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 520px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 2.5%;
    margin-left: 150px;
    margin-right: 150px;
    z-index: 1;
    margin-top: 2%;
    width: 80%;
  }

  .copyright{
    font-size: 13px;
    margin-bottom: none;
  }

  .loginTitle{
    font-size: 35px;
  }
}


@media (max-width: 768px) {
  .login-container {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100% !important;
  }
}


@media (max-width: 820px) {
  .login-container {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 60vh;
  }
}

@media (max-width: 1024px) {
  .login-container {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    height: 70% !important;
  }
}




