/* Headline.css */

.headline {
  background-image: url('../../Images/HeaderImage.jpg');
  /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg);  */
  width: 100%;
  height: 87vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden; 
  position: relative; 
  z-index: 3;
}







/*  */

.headlineContainer {
  width: 100%;
  margin-right: 150px;
  margin-left: -120px;
  margin-top: 9%;
}

.headline h2 {
  font-size: 350%;
  margin-bottom: 15px; 
  font-family: futura;
}

.headline p {
  font-size: 100%;
  margin-bottom: 0px; /* Add some spacing between paragraphs */
}

.sayHelloButton {
  background: #f6c90e;
  height: 50px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #303841;
  margin-bottom: 20px; /* Add some spacing between button and lower-right content */
}

.lower-right-content {
  position: relative;
  bottom: -70px;
}

.smaller-h2 {
  font-size: 200%;
}


/* Media query for smaller screens */


@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .headlineContainer {
    display: flex !important;
    align-items: center  !important;
    flex-direction: column  !important;
    justify-content: center  !important;
    width: 100%  !important;
    font-size: 20px  !important;
  
  } 

  .headline h2 {
    font-size: 30px !important;
    margin-bottom: 15px  !important; 
    font-family: futura;

  }

  .headline p {
    font-size: 18px !important;
    margin-bottom: 0px  !important; /* Add some spacing between paragraphs */
  }
  
}



@media (max-width: 767px) {
  .headlineContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-left: 55px !important;
    margin-right: 150px !important;
  } 
  .headline p {
    font-size: 22px !important;
    margin-bottom: 0px; /* Add some spacing between paragraphs */
  }
  
}



@media (max-width: 768px) {
  .headline {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 40px 20px; /* Adjust the padding for smaller screens */
 
  }

  .lower-right-content {
    position: static;
    align-items: center;
    margin-left: 15%;
    margin-top: 10%;
    
  }

  .headlineContainer {
    left: 0; /* Set the left property to 0 to align to the left side */
    z-index: 1;
  }

  .headline h2 {
    font-size: 250%; /* Reduce the font size for smaller screens */
    text-align: center; /* Center align the h2 for smaller screens */
  }

  .headline p {
    font-size: 100%;
    text-align: center; /* Center align the paragraphs for smaller screens */
  }

  .sayHelloButton {
    margin-bottom: 40px; /* Add more spacing for the button */
  }
}


@media (max-width: 1024px) {
  /* .headline {
    padding: 40px 20px; 
    
  }

  .headline h2 {
    margin-left: 15%;
  }

  .headline p {
    font-size: 70%;
    margin-left: 15%;
  } */

  .headline {
    zoom: 80% !important;
  }
}

@media (max-width: 768px) {
  /* .headline {
    padding: 40px 20px; 
    
  }

  .headline h2 {
    margin-left: 15%;
  }

  .headline p {
    font-size: 70%;
    margin-left: 15%;
  } */

  .headline {
    zoom: 60% !important;
  }
}




@media (max-width: 320px ) {
    .headline h2
    {
      margin-left: 30%;
    }
    .headline p 
    {
        margin-left:25%;
    }
}

@media (max-width: 1900px)
  {
    .headline h2 {
      font-size: 275%;
      margin-bottom: 15px; 
      font-family: futura;
    }
    
    .headline p {
      font-size: 100%;
      margin-bottom: 0px; /* Add some spacing between paragraphs */
    }

    .headlineContainer {
      width: 100%;
      margin-right: 150px; 
      margin-left: -120px;
      margin-top: 5%;
    }
  }


