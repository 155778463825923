/* Navbar styles */
.navbar {
  background-color: #f6c90e;
  display: flex;
  align-items: center;
  padding: 1px;
  height: auto;
  min-height: 80px;
  font-family: futuraheavy, sans-serif; /* Add a fallback sans-serif font */
  font-size: 20px;
  width: 100%;
  /* zoom: 90%; */
}

.navContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-left: 150px;
  margin-right: 150px;
}

.logo {
  display: flex;
  align-items: center;
  width: 20%;
  height: 70px;
  background-image: url("../Images/Logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: center;
}

.navText {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 9px;
}

.navText a {
  color: #141212;
  text-decoration: none;
  margin: 10px;
  position: relative; /* Required for positioning the line under the link */
}

/* Style for the active link */
.navText a.active::before {
  content: "";
  display: block;
  height: 4px; /* Adjust the height to match the height of the navText links */
  width: 100%;
  background-color: #141212;
  position: absolute;
  bottom: -4px;
}

/* Style for the hover state */
.navText a:hover::before {
  content: "";
  display: block;
  height: 4px; /* Adjust the height to match the height of the navText links */
  width: 100%;
  background-color: #141212;
  position: absolute;
  bottom: -4px;
}

.userProfileLink {
  text-decoration: none;
  font-size: 100% !important;
  font-weight: bold;
  color: #141212;
  text-transform: uppercase;
  margin-top: 4px;

  margin-bottom: 7px;
}

.user-logout-container {
  display: flex;
  align-items: center;
  position: relative; /* Add relative positioning */
}

.user {
  color: #303841; /* Change the color to #303841 */
  margin-right: 20px;
  align-items: center;
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.auth-buttons a {
  text-decoration: none;
  border: 2px solid #141212;
  border-radius: 5px;
  margin-left: 5px;
  max-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
}

.logout-button {
  color: #f6c90e;
  background-color: #141212;
  padding: 2px;
  height: 100%;
  font-size: 90%;
}

.logout-button:hover {
  background-color: transparent;
  color: #141212;
}

.register-button {
  background-color: transparent;
  color: #141212;
  height: 100%;
  padding: 2px;
  font-size: 90%;
}

.register-button:hover {
  background-color: #141212;
  color: #f6c90e;
}

/* Default underline for the "Home" link */
.navText a[href="/"] {
  border-bottom: 4px solid #141212; /* Add a bottom border on hover */
}
.dropdown-item {
  font-family: "futuramedium";
}
.dropdown-item:hover,
.dropdown-item.active {
  background-color: #f6c90e !important;
  color: white !important;
}

/* Media query for smaller screens */

/* @media (max-width: 768px) {
  .logo img {
    width: 80%;
  }

  .logo {
    margin: 0 auto;
  }

  .navText {
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  .navText a {
    margin: 5px;
  }

  .auth-buttons {
    margin-top: 5px;
  }
} */

@media (max-width: 320px) {
  .logo {
    margin-left: 20%;
  }
}

@media (max-width: 1900px) {
  .navContainer {
    max-height: 11vh;
    margin-left: 150px;
    margin-right: 150px;
    zoom: 95%;
  }

  .navText {
    font-size: 80%;
  }

  .logo {
    width: 25%;
  }

  .auth-buttons {
    font-size: 85%;
  }

  .userProfileLink {
    font-size: 90% !important;
  }
}

@media (max-width: 1024px) {
  .navContainer {
    max-height: 11vh;
    margin-left: 50px;
    margin-right: 50px;
    zoom: 70%;
    max-width: 100%;
  }

  .navText {
    font-size: 20px;
  }

  .logo {
    width: 25%;
  }

  .auth-buttons {
    font-size: 60%;
  }

  .userProfileLink {
    font-size: 90% !important;
  }
}

@media (max-width: 820px) {
  .navContainer {
    max-height: 11vh;
    margin-left: 50px;
    margin-right: 50px;
    zoom: 70%;
    max-width: 100%;
  }

  .navText {
    font-size: 12px;
  }

  .logo {
    width: 25%;
  }

  .auth-buttons {
    font-size: 60%;
  }

  .userProfileLink {
    font-size: 90% !important;
  }
}

@media (max-width: 768px) {
  .navContainer {
    max-height: 11vh;
    margin-left: 50px;
    margin-right: 50px;
    zoom: 70%;
    max-width: 100%;
  }

  .navText {
    font-size: 12px;
  }

  .logo {
    width: 25%;
  }

  .auth-buttons {
    font-size: 60%;
  }

  .userProfileLink {
    font-size: 90% !important;
  }
}

@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .navText {
    font-size: 9px !important;
  }

  .navContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  /* Navbar styles */
  .navbar {

    min-height: 60px;

  }
}

@media only screen and (max-width: 375px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  .navText {
    font-size: 7px !important;
  }
}

@media (max-width: 767px) {
  /* Navbar styles */
  .navbar {
    display: flex;
    align-items: center;
    padding: 1px;
    height: auto;
    width: 100%;
    justify-content: space-between;
    min-height: 60px;

    /* zoom: 90%; */
  }

  .navbaralert {
    zoom: 60%;
  }

  .navContainer {
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    display: flex;
  }

  .navText {
    font-size: 10px;
  }

  .logo {
    width: 25%;
  }

  .auth-buttons {
    font-size: 60%;
  }

  .userProfileLink {
    font-size: 90% !important;
  }
}

@media (max-width: 375px) {
  /* Navbar styles */
  .navbar {
    display: flex;
    align-items: center;
    padding: 1px;
    height: auto;
    width: 100%;
    justify-content: space-between;

    /* zoom: 90%; */
  }

  .navText {
    font-size: 9px !important;
  }
}
