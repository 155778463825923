.userProfileContainer {
    display: flex;
    justify-content: space-between;
    margin-left: 150px;
    margin-right: 150px;
    justify-content: center;
    align-items: center;
    margin-top: 75px;
  }
  
  .profilePictureContainer {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    max-width: 30%;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 75vh;
  }
  
  .changePhotoButton {
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
  }
  
  .userInfoContainer {
    margin-top: 20px;
    text-align: center;
  }
  
  .accountInfoContainer {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .accountTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Vertically center the title and line */
    font-family: futuramedium;
  }
  
  .accountTitle hr {
    flex-grow: 1; /* Allow the line to fill remaining space */
    height: 1px;
    background-color: #ccc;
    margin-left: 10px; /* Add margin to adjust the position */
  }
  
  .editButtons button {
    margin-right: 10px;
    font-family: futuramedium;
  }
  
  .accountDetails {
    margin-top: 0;
  }
  
  .infoTitle{
    font-size: 25px;
    margin-bottom: -3px;
  }
  
  /* Additional styling, adjust as needed */
  

  @media screen and (max-width: 1024px) {
    .userProfileContainer {
     zoom: 80% !important;
    }

  }

  @media screen and (max-width: 768px) {
    .userProfileContainer {
     zoom: 60% !important;
    }

  }