/* AddUserModal.css */
.add-user-modal {
    position: fixed;
  
  }
  
  .modal-content {
    height: 100%;
    width: 100%;
    
  }
  


  .addUserDetails{
    border-bottom: 2px solid #7e7e7e; /* Add a line below finalDetails */
    padding-bottom: 4px; /* Gap from the finalDetails text */
    font-size: 15px;
    height:20px;
    width: 100%;
    margin-bottom: 10px;
  }

  .addUserDetails::placeholder {
    font-weight: normal;
  }

    .addressRow {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px; /* You can adjust the gap value to your preference */
  }

  .addressColumn {
    flex-grow:0;
    max-width: none;
  }

  .addressRow2
  {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }

  .custom-modal-content {
    /* Set the maximum width you want for the modal content */
    min-width: 900px; /* Adjust as needed */
  
    /* Center the content horizontally within the modal body */
    margin: 0 auto;
  }
  
  @media screen and (max-width: 1024px) {
    .ModalChildDiv {
     zoom: 80% !important;
     width: 100% !important;
    }
  
  }
  
  
  @media screen and (max-width: 768px) {
    .ModalChildDiv {
     zoom: 55% !important;
     width: 100%;
    }
  
  }
  