.selectCompanyContainer{
    background-image: url('../../Images/HeaderImage.jpg');
    /* background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/A_black_image.jpg/640px-A_black_image.jpg);  */
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; 
    position: relative; 
    z-index: 4;
}

.logoContainer-selectCompany {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    background-image: url('../../Images/Logo.png');
    background-size: contain; 
    justify-content: center;
    margin-bottom: 30px;
    background-repeat: no-repeat;
  
  }

.selectCompanyOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control darkness */
    z-index: -1; /* Place the overlay below the content */
  }

.selectionContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.selectContainer{
    background-color: #F6C90E;
    padding: 50px;
    border-radius: 25px;
    border: 1px solid #100101;
}

/* selectCompany.css */

.companyDropdown {
    /* Add your dropdown styling here */
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: white;
    font-family: futurabook;
  }
  
  .companyOption {
    /* Add your option styling here */
    font-size: 14px;
    padding: 5px;
  }
  
  /* Add more styles as needed */
  