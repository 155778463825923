/* RegisterBar.css (create a new CSS file for RegisterBar component or add to existing CSS file) */
.bar-container {
  width: 100%; 
  font-family: 'futurabook', sans-serif;

}

.register-bar {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  gap: 10%;
  padding-top: 15px;
  
}

.register-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
  opacity: 50%;
}

.register-icon.active {
  color: #000000;
  opacity: 100%;
  font-weight: bold;
}

.register-icon svg {
  font-size: 25px;
  margin-bottom: 5px;
}

/* Yellow bar below active component */
.register-icon.active::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #000000;
  bottom: -1px;
  left: 0;
}

.next-button,
.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #141212;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 17px;
  cursor: pointer;
  margin-bottom: 12px;
  width: 15%;
  height:60px;
  font-family: "futura";
}

.next-button {
  background-color: #f6c90e;
}

.back-button {
  background-color: #efefef;
}

.login-icon {
  font-size: 20px;
  margin-left: 8px;
}

.component-navigation {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.passwordValidation {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.passwordValidation p {
  font-size: 8px;
}


@media screen and (max-width: 1024px) {
  .bar-container {
   zoom: 80% !important;
  }

}

@media screen and (max-width: 768px) {
  .bar-container {
   zoom: 60% !important;
  }

}