/* userApproval.css */

/* Remove background color from dropdown toggle and menu */
.approval-dropdown,
.approval-dropdown.dropdown-toggle,
.approval-dropdown.dropdown-menu {
  background-color: transparent !important;
  color: black;
  border-color: transparent;
}

/* Apply styles on hover */
.approval-dropdown:hover,
.approval-dropdown.dropdown-toggle:hover,
.approval-dropdown.dropdown-menu:hover {
  background-color: transparent !important;
  color: black;
  border-color: transparent;
}

/* userApproval.css */



/* UserApprovalTable.css */

/* Style for the table */
.approvalTable {
  border-collapse: separate; /* Use separate for border-spacing */
  border-spacing: 0 15px; /* Add space between rows and columns */
  width: 100%;
  text-align: center;
  zoom: 90%;
}

/* Apply padding to table cells for spacing */
.approvalTable th,
.approvalTable td {
  padding-top: 10px; /* Adjust padding as needed */
  padding-right: 1px;
  padding-bottom: 10px;

}



.approvalTable th:last-child,
.approvalTable td:last-child {
  border-right: none;
}

.approvalTable tbody tr {
  border-bottom: 1.5px solid #3A4750; /* Add line at the bottom */
  border-top: 1.5px solid #3A4750;
  width: 100%;
  justify-content: space-between;

}

.approvalTable tbody tr:hover {
  background-color: transparent;
}

.approvalTable tbody tr.selected {
  background-color: #F6C90B17;
  color: rgb(0, 0, 0);
}

/* Style for dropdowns */
.approvalTable select {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.icon {
  font-size: 16px;
  border: transparent 1px;
  opacity: 70%;
}


.iconBar {
    font-size: 18px;
    color: #000000;
    margin-right: 5px;
    opacity: 80%;
    align-items: center;
    justify-content: center;
    margin-top: -4px;
    
}

.searchicon{
  opacity: 80%;
  margin-right: 7px;
}
/* Your other styles here */

.save-button{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #141212;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 12px;
  width: 100px;
  padding: 5px;
  height:60px;
  font-family: "futura";
  background-color: #f6c90e;
  margin-top: 35px;
}


/* Add thicker line before and after table */


/* In your userApproval.css or a separate CSS file */

.checkbox-dropdown {
  display: flex;
  align-items: center;
  max-width: 10%;
}

.dropdown {
  position: relative;
  margin-left: 8px;
}

.dropdown-arrow {
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 0;
  min-width: 100px;
  z-index: 1;
}

.dropdown-content div {
  padding: 8px;
  cursor: pointer;
 
}


/* CSS file */
.normal-icon {
  margin-top: 0px;
}

.active-icon {
  fill: #F6C90E; /* Change to the desired color */
  margin-top: 0px;
}




.dropdown:hover .dropdown-content {
  display: block;
  font-family: futurabook;
  font-size: 10px;
  border-radius: 10px;
}

.tHead{
  font-size: 17px;
  font-family: futurabook;
  width: 100%;
  justify-content: space-between;
}

.tBody{
  font-size: 18px;
  font-family: futurabook;
  justify-content: space-between;
  width: 100%;
}

.minibar{
  font-size: 14px;
}

.tableHeader{
  min-width: 190px ;
}

.tableHeaderBig{
  min-width: 260px ;
}


@media (max-width: 1900px){

  .tableHeader{
    min-width: 170px ;
  }
  
  .tableHeaderBig{
    min-width: 150px ;
  }


  .tBody{
    font-size: 15px;
    font-family: futurabook;
    justify-content: space-between;
    width: 100%;
  }

  .tHead{
    font-size: 14px;
    font-family: futurabook;
    width: 100%;
    justify-content: space-between;
  }

  .approvalTable th,
.approvalTable td {
  padding-top: 9px; 
  padding-right: 11px;
  padding-left: 15px;
  padding-bottom: 9px;
}





}

.approvalTable {
  border-collapse: separate;
  border-spacing: 0 15px;
  width: 100%;
  text-align: center;
  
}

@media (max-width: 2880px) {
  .approvalTable {
    zoom: 75%; /* Adjust zoom level as needed */
  }

  .userapprovalbar{
    zoom:90%
  }
}

@media (max-width: 992px) {
  .approvalTable {
    zoom: 60%;
  }
}


@media (max-width: 1024px) {
  .approvalTable {
    zoom: 70%;
    margin-left: 25px;
    margin-right: 25px;
  }

  .userapprovalbar{
    max-width: 100% !important;

  }

  .approvalBarContents {
    margin-left: 80px !important;
    margin-right: 80px !important;
    zoom: 80% !important;
  }


  .TablePageMainDiv{
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  

  
.save-button{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #141212;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 12px;
  width: 100px;
  height:50px;
  font-family: "futura";
  background-color: #f6c90e;
  margin-top: 35px;
}
}

@media (max-width: 768px) {
  .approvalTable {
    zoom: 50%;
    margin-left: 25px;
    margin-right: 25px;
  }

  .userapprovalbar{
    max-width: 100% !important;

  }

  .approvalBarContents {
    margin-left: 50px !important;
    margin-right: 50px !important;
    zoom: 80% !important;
  }

  .TablePageMainDiv{
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

.approvalTitle{
  font-size: 25px !important;
}
  
  
.save-button{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #141212;
  border: none;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 12px;
  width: 100px;
  height:30px;
  font-family: "futura";
  background-color: #f6c90e;
  margin-top: 35px;

}
}

@media (max-width: 576px) {
  .approvalTable {
    zoom: 40%;
  }
}




