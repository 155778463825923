@font-face {
  font-family: 'futura';
  src: url('./Fonts/FuturaPTHeavy.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'futurabook';
  src: url('./Fonts/FuturaBookfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'futuramedium';
  src: url('./Fonts/futura\ medium\ bt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'futuraheavy';
  src: url('./Fonts/Futura\ Heavy\ font.ttf') format('truetype');
  font-weight: normal;
  font-size: normal;
}

@font-face {
  font-family: 'futurademi';
  src: url('./Fonts/futura\ medium\ condensed\ bt.ttf') format('truetype');
  font-weight: normal;
  font-size: normal;
}

@font-face {
  font-family: 'futurabold';
  src: url('./Fonts/Futura\ Bold\ font.ttf') format('truetype');
  font-weight: normal;
  font-size: normal;
}

input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  color: #020202;
  position: relative;
}

input[type="date"]::-webkit-datetime-edit-year-field::before,
input[type="date"]::-webkit-datetime-edit-month-field::before,
input[type="date"]::-webkit-datetime-edit-day-field::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #8c8c8c;
  transform: skewX(45deg); /* Adjust the skew angle as needed */
}

/* Adjust the left property to create a gap between the border and the text */
input[type="date"]::-webkit-datetime-edit-year-field::before {
  left: 95%;
}

input[type="date"]::-webkit-datetime-edit-month-field::before {
  left: 95%;
}

input[type="date"]::-webkit-datetime-edit-day-field::before {
  left: 95%;
}

*{
  zoom: 100%;
}

/* Add this rule for iOS devices when isMobile is true */
/* @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
  * {
    zoom: 85%;
  }
} */
