.firstImage{
    background-image: url('../../Images/Safe\ T\ Tag\ AD\ Dec\ 2023.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    background-position-x:center ;
    background-position-y: center;
}

.secondImage{
    background-image: url('../../Images/Untitled\ design\ \(6\).png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    background-position-x:center;
    background-position-y: center;
}


@media (max-width: 767px) {
   
    .galleryDiv{
        zoom: 60%;
    }
    
    
}

@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
    .galleryH{
        font-size: 22px !important;
    }

    .galleryDiv{
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    
}