/* SafeTtagFeatures.css */

.featuresContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #303841;
    padding-top: 60px;
    padding-bottom: 30px;
    height: 100%;
  
  }

  .featureIcon1 {
    background-image: url('../../Icons/award.png');
    background-size: contain;
    height: 150px;
    width: 120px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    color: #eeee;
  
    /* Apply a color tint to the image */
   
  }
  

  .featureIcon2 {
    background-image: url('../../Icons/coin.png');
    background-size: contain;
    height: 150px;
    width: 120px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
  }

  .featureIcon3 {
    background-image: url('../../Icons/shield.png');
    background-size: contain;
    height: 150px;
    width: 120px;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    color: #eeee;

  }
  
  .featuresContainer > div:first-child {
    margin-bottom: 30px;

  }
  
  .featuresContainer h3 {
    color: #eee;
    
  }

  .featuresContainer h2 {
    color: #eeee;
    
  }

  .featuresContainer p {
    color: #eeee;
  }
  
  .featuresContainer h2 {
    font-size: 30px;
    margin: 0;
    
  }

  


  
  .featuresContainer > div:last-child {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  
  .featuresContainer > div:last-child > div {
    flex: 1;
    margin: 15px;
    padding: 15px;
    border: 2px solid #eeee;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
    height:60vh;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  
  .featuresContainer h3 {
    margin-top: 0;
    
  }
  
  .featuresContainer p {
    margin-bottom: 0;
  }
  
  /* Icons color */
  .featuresContainer svg {
    color: #eeee;
    transition: color 0.4s ease; /* Add transition for smooth icon color change */
  }
  
  /* Hover effect for icons */
  /* .featuresContainer > div:last-child > div:hover svg {
    color: #F6C90E; 
  }
   */
  /* Hover effect for feature boxes */
  /* .featuresContainer > div:last-child > div:hover {
    border-color: #F6C90E; /* Change border color to #F6C90E on hover */
    /* cursor: pointer;
  } */
   
  @media (max-width: 1024px) {
    /* .headline {
      padding: 40px 20px; 
      
    }
  
    .headline h2 {
      margin-left: 15%;
    }
  
    .headline p {
      font-size: 70%;
      margin-left: 15%;
    } */
  
    .featuresContainer {
      zoom: 80% !important;
    }
  }



  @media (max-width: 768px) {
    /* .headline {
      padding: 40px 20px; 
      
    }
  
    .headline h2 {
      margin-left: 15%;
    }
  
    .headline p {
      font-size: 70%;
      margin-left: 15%;
    } */
  
    .featuresContainer {
      zoom: 60% !important;
    }
  }


  @media (max-width: 767px) {
    /* .headline {
      padding: 40px 20px; 
      
    }
  
    .headline h2 {
      margin-left: 15%;
    }
  
    .headline p {
      font-size: 70%;
      margin-left: 15%;
    } */
  
    .featuresContainer {
      zoom:75% !important;
    }
  }

  
  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {

    .featuresTitle{
      font-size: 17px !important;
    }

    .featurestitle2{
      font-size: 22px !important;
    }

    .featureSection{
      margin-left: 15px !important;
      margin-right: 15px !important;
    }

    .featureblockktitle{
      font-size: 22px !important;
    }

    .featureblockktitle1{
      font-size: 20px !important;
    }

    .featureBlock{
      min-height: 1080px !important;
    }
    
    .plugP{
      margin-top: 80px !important;
    }
    
    .locklightningicon{
      margin-top: 80px;
    }
    
  }



  
  @media only screen and (max-width: 767px) {

    .featuresTitle{
      font-size: 17px ;
    }

    .featurestitle2{
      font-size: 30px ;
    }

    .featureSection{
      margin-left: 15px ;
      margin-right: 15px ;
    }

    .featureblockktitle{
      font-size: 22px ;
    }

    .featureblockktitle1{
      font-size: 20px ;
    }

    .featureBlock{
      min-height: 650px ;
    }
    
    .plugP{
      margin-top: 80px ;
    }
    
    .locklightningicon{
      margin-top: 80px;
    }

  }

