.companyDropbar {
    border: none;
    appearance: none;
    background-color: transparent;
    width: 100%;
    font-size: 16px;
    font-family: futurabook;
  }
  
.companydropdown-toggle{
    font-family: futuramedium;
    color: #837070;
    font-weight: bold;
}

.companyDropdown{
    border: transparent;
}

.profile-col {
    position: relative;
  }
  
  /* input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  } */
  
  .company-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #fff;
  }
  
  .company-dropdown li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .company-dropdown li:hover {
    background-color: #f0f0f0; /* Change the background color on hover */
  }
  

  .custom-input::placeholder {
    color: black;
  }

  .react-dropdown-select {
    color: black; /* Text color for selected value */
  }
  
  .react-dropdown-select .react-dropdown-select-placeholder {
    color: black; /* Placeholder text color */
  }