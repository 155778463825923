/* OfficialDistributors.css */
.officialDistributors {
    background-color: #F6C90E;
    text-align: center;
    padding: 5px;

  }
  
  .h4Distributor{
    font-family: futura;
    font-size: 18px;
    color: #141212;
  }

  .DistributorP{
    font-size: 14px;
    margin-top: -1px;
    color: #141212;
  }


  .title h3 {
    margin: 0;
    color: #141212;
    text-align: center;
    margin-top: 5vh;
    font-family: futura;
  }
  
  .distributorList {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }
  
  .distributorItem {
  
    text-align: center;
  }
  
  .iconDistributor {
    color: #141212 ;
   
    height: 150px;
    width: 600px;
    background-image: url('../../Images/ipp_logo.png');
    background-size: contain;

    background-repeat: no-repeat;
  }
  
  .iconImage {
    vertical-align: middle;
  }
  
  h4 {
    margin: 10px 0;
    
  }
  
  h5,
  p {
    margin: 5px 0;
  }
  


  
  @media screen and (max-width: 767px) {
    .distributorItem {
      max-width: 100%;
    }
  
    /* Decrease the font-size of the icon for smaller screens */
    .icon {
      font-size: 100px;
    }

    .officialDistributors{
      zoom: 60%;
      
    }

    .iconDistributor {
      color: #141212 ;
     
      height: 100px;
      width: 100%;
      background-image: url('../../Images/ipp_logo.png');
      background-size: contain;
      background-repeat: no-repeat;

    }

  }



  
  @media screen and (max-width: 768px) {
    /* Further decrease the font-size of the icon for even smaller screens */
    .icon {
      font-size: 80px;
    }
  }

  


  /* Responsive Layout */
  @media screen and (max-width: 1024px) {
    .distributorItem {
      max-width: 100%;
    }
  
    /* Decrease the font-size of the icon for smaller screens */
    .icon {
      font-size: 100px;
    }

    .officialDistributors{
      zoom: 80%;
    }
  }


  @media screen and (max-width: 768px) {
    .distributorItem {
      max-width: 100%;
    }
  
    /* Decrease the font-size of the icon for smaller screens */
    .icon {
      font-size: 100px;
    }

    .officialDistributors{
      zoom: 60%;
    }
  }
  
  @media screen and (max-width: 480px) {
    /* Further decrease the font-size of the icon for even smaller screens */
    .icon {
      font-size: 80px;
    }
  }

  @media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {

    .distributorList{
      margin-left: 15px !important;
      margin-right: 15px !important;
    }

    .iconDistributor{
      width: 100% !important;
      background-size: contain !important;
    }
  }
  


  @media only screen and (max-width: 767px) {
    .distributorList{
      margin-left: 15px ;
      margin-right: 15px ;
    }

    .iconDistributor{
      width: 100% !important;
      background-size: contain;
    }
  }
  