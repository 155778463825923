/* HowToBuy.css */
.howToBuy {
    background: rgb(48, 56, 65);
    color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content horizontally */
  }
  
  .howToBuy h3 {
    color: #eeee;
  }
  
  .howToBuy > div {
    display: flex;
    flex-wrap: wrap;
  }
  
  .howToBuy > div > div {
    flex: 1;
    min-width: 100px;
    margin: 10px;
    text-align: center;
  }
  
  /* Styles for the icons */
  .howToBuy .icon {
    font-size: 70px;
    color: #F6C90E;
  }
  
  .howToBuy p {
    color: #fff;
  }
  
  .howToBuy button {
    background-color: #F6C90E;
    color: #303841;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    margin-top: 2px;
  }
  