.distributorImgContainer{
    background-image: url('../../Images/ipp_logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
}

.distributorQrContainer{
    background-image: url('../../Images/Ippwaqr.png');
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
}



@media (max-width: 768px) {
   .distributorDetailsMainDiv{
    zoom: 60% !important;
   }
}

@media (max-width: 1024px) {
    .distributorDetailsMainDiv{
     zoom: 80% !important;
    }
 }

@media (max-width: 767px) {
    .distributorImgContainer{
        zoom: 10%;
    } 




    .distributorDetailsMainDiv{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        zoom: 60% !important;
        
    }

    .contactInfo{
        display: flex;
        flex-direction: column;
    }


.distributorheadline{
    margin: 10px !important;
    zoom: 55%;
}


.distributorMapContainer{
    margin: 10px !important;
    zoom: 60%;
}


}

@media only screen and (max-width: 767px) and (-webkit-min-device-pixel-ratio: 2) and (hover: none) and (pointer: coarse) {
    .distributorsHeaderDiv{
        margin-left: 20px  !important; 
        margin-right: 20px !important;

    }

    .headerTextdistributor{
        font-size: 22px !important;
    }

    .DistributorP{
        font-size: 19px !important;
    }
    
    .infoName{
        font-size: 18px !important;
    }

    .contactInfo{
        gap: 10px !important;
    }

    .distributorheadlineP1{
        font-size: 20px !important;
        margin-left: 30px;
        margin-right: 30px;
    }

    .distributorheadline{
        margin-top: 80px !important;
    }

    

}