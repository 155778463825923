.register-container { 
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 70vh;
        background-color: #ffffff;
        border-radius: 20px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        padding: 30px;
        z-index: 1;
        margin-top: 2%;
        align-items: center;
}

input::placeholder {
  color: #707070; /* Replace with your desired darker color */
}


.next-button {
  /* your existing styles */
  cursor: pointer;
  /* Add styles for the disabled state */
  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}


.profile-row {
    display: flex;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-bottom: 10px;
    min-width: 100%;
    justify-content: flex-start;
   
  }
  
  .profile-col {
    display: flex;
    align-items: center;
    border: 1px solid #303841;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 12px;
    width: 100%;
    height: 60px;

  }

  .icon-col{
    display: flex;
    align-items: center;
    border: 1px solid #303841;
    border-radius: 8px;
        padding: 8px;
    margin-bottom: 12px;
    width: 10%;
    height: 100%;
  }
  
  .email-col {
    grid-column: span 2;
  }


  /* password */



/* UserProfile.css */

.password-strength-bar {
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 10px;
  width: 50%;
}

.strength-meter {
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin-right: 5px; /* Add margin between each strength meter */
}

.strength-1 {
  width: 20%;
  background-color: red;
}

.strength-2 {
  width: 40%;
  background-color: orange;
}

.strength-3 {
  width: 60%;
  background-color: yellow;
}

.strength-4 {
  width: 80%;
  background-color: greenyellow;
}

.strength-5 {
  width: 100%;
  background-color: green;
}

.strength-labels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 5px;
  font-size: 10px;
}

.weak {
  text-align: left;
}

.strong {
  text-align: right;
}

/* calendar */

/* YourComponent.css */

.calendar-icon {
  cursor: pointer;
  color: #303841;
}

.calendar-popup {
  position: absolute;
  margin-top:13%;
  margin-left: -50px;
  background-color:none;
  /* border: 1px solid #ccc; */
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  z-index: 1;
}

/* .custom-calendar {
  /* Add your custom styling for the calendar */
  /* You can adjust the width, height, colors, etc. here */

.register-Error{
  position: absolute;
  color: red;
  background-color: pink;
}

.register-ErrorContainer {
  height: 40px;
  width: 225px;
  background-color: rgb(255, 75, 75); 
  position: absolute;
  justify-content: center;
  align-items: center; /* Center vertically */
  display: flex;
  margin-top: 7px;
  margin-left: 5px;
  border-radius: 5px;
  z-index: 999;
}


.red-border {
  border: 1px solid red;
}



@media screen and (max-width: 1024px) {
  .finalDetails-container {
   zoom: 80% !important;
   width: 90% !important;
  }

}


@media screen and (max-width: 768px) {
  .finalDetails-container {
   zoom: 60% !important;
   width: 90% !important;
  }

}
