.notification-card {
    position: absolute;
   
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    width: 225px;
    margin-left: -60px;
    margin-top: 10px;
    z-index: 9999999;
    background-color: white;
}

.notification-card-header{
    background-color: #3A4750;
    display: flex;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    justify-content: space-between;
}

.notification-count {
    background-color: #F6C90E;
    color: #3A4750;
}

.notification-list{
    border-left: 4px solid #F6C90E;
    width: 100%;  
    display: flex;
    flex-direction: column;

}

.notification-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 2px solid #F6C90E0D;
}


